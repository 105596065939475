<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header marco-login row">
            <div class="col-sm-6" :style="{
              backgroundImage: 'url(' + require('@/assets/img/carrera-caballos.jpg') + ')',
              backgroundSize: 'auto 100%',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat'
            }">
            </div>
            <div class="col-sm-6 centrado">
              <div class="">
                <h1>Tipster Verde</h1>
                <div v-if="modo=='login'" key="login">
                  <p>¿Tienes cuenta?</p>
                  <div class="text-center btn-tipster" @click="InicioSesionModal()">
                    <div class="text-sm">
                      <span>Inicia sesión</span>
                    </div>
                  </div>
                  <div class="text-center btn-neutro">
                    <div class="text-sm">
                      <span>Accede con Google</span>
                    </div>
                  </div>
                  <hr/>
                  <p>O regístrate</p>
                  <div class="text-center btn-neutro" @click="modo='registro'">
                    <div class="text-sm">
                      <span>Crear Cuenta</span>
                    </div>
                  </div> 
                </div>
                <div v-if="modo!='login'" key="registro">
                  <registro-usuario-form @volver-registro="() => {modo='login'}"></registro-usuario-form>                    
                </div>
              </div>
            </div>
      </div>
    </section>
  </main>
<!--<app-footer />-->
  <Modal @close="InicioSesionModal" @cerrar-modal="cerrarModal" :modalActive="modalActive">
    <div class="modal-content">
      <inicio-sesion-form @cerrar-modal="() => { cerrarModal(); }" :key="0"></inicio-sesion-form>
    </div>
  </Modal>
</template>

<script>
/*import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";*/
import InicioSesionForm from './components/InicioSesionForm.vue';
import RegistroUsuarioForm from './components/RegistroUsuarioForm.vue';
import Modal from './components/Modal.vue';
import showSwal from "/src/mixins/showSwal.js";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import * as yup from "yup";
import {ref} from 'vue';

export default {
  name: "Login",
  components: {
/*    Navbar,
    AppFooter*/
    Modal,
    InicioSesionForm,
    RegistroUsuarioForm
  },

  data() {
    const schema = yup.object().shape({
      usuario: yup.string().required("Debe indicar su usuario!"),
      password: yup.string().required("Debe indicar su clave!"),
    });
    const user = {
      usuario: "",
      password: "",
    };
    const modo = 'login';
    return {
      loading: false,
      user,
      schema,
      modo
    };
  },
  created() {
    this.modo = 'login';
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async handleLogin() {
      this.loading = true;
      try {
        await this.$store.dispatch("auth/login", this.user);
        this.$router.push("/dashboard");
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Datos de acceso erróneos",
        });
        this.loading = false;
      }
    },
  },
  setup() {
    const modalActive = ref(false);
    const InicioSesionModal = () => {
      modalActive.value = !modalActive.value;
    };
    const cerrarModal = () => { 
      modalActive.value = false;
    };
    return { modalActive, InicioSesionModal, cerrarModal};
  }
};
</script>
<style>
  .marco-login {height: 100vh !important;margin:0!important;}
  .marco-login > div {height:100%;}
  .centrado {display: flex; justify-content: center; align-items: center; height: 100vh;}
  .centrado > div {width:300px;}
  .centrado h1 {font-size: 2.5rem;line-height: 200%;}
  .centrado p {font-size: 1.35rem;font-weight: 600;color: #333;margin: 0;line-height: 2.5rem;}
  .text-center{max-width: 300px;border:1px solid #333;border-radius:20px;margin:0.25rem 0 1rem 0;color:#333;cursor:pointer;}
  .text-center .text-sm {font-size: 1.1rem !important;line-height:200%;}
  hr{margin:2rem 0!important;}
</style>
