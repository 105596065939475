<template>
  <nav class="" data-scroll="true">
    <div class="cabecera btn-dark" id="navcabecera">
      <div class="left">
        <router-link class="nav-link m-0 navbar-brand" :to="{name: 'Profile'}">
          <img :src="logo" class="navbar-brand-img h-100" alt="Tipster Verde" />
        </router-link>
      </div>
      <div class="right" v-if="loggedIn">
        <p class="estilo-trabajador"><i class="fa fa-user me-sm-1" aria-hidden="true"></i> {{ trabajadorTxt }}</p>
        <a class="px-0 nav-link font-weight-bold text-body desconectar" @click="logoutUser">
          <i class="fa fa-power-off me-sm-1" aria-hidden="true"></i>
        </a>
      </div>
      <div class="right" v-if="!loggedIn">
        <p class="estilo-trabajador"><i class="fa fa-user me-sm-1" aria-hidden="true" @click="loginUser"></i> {{ trabajadorTxt }}</p>
      </div>
    </div>
  </nav>
</template>

<script>
import logo from "@/assets/img/sports_cards.jpg";

export default {
  name: "NavCabecera",
  data() {
    return {
      logo,
      trabajadorTxt: localStorage.getItem("usuarioNombre"),
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    }
  },
  created() {
  },
  methods: { 
    async logoutUser() {
      try {
        await this.$store.dispatch("auth/logout");
      } finally {
        this.$router.push("/login");
      }
    },
    async loginUser() {
      this.$router.push("/login");
    }
  },
  components: {
  },
  updated() {
  },
};
</script>

<style>
.configuracion {padding:0;margin:0;width:50px;text-align: center;}
.estilo-trabajador {margin:0 15px;font-weight:600;}
.cabecera:hover {background-color:var(--bs-dark)!important;}
</style>