<template>
    <h5 class="mb-4">Inicie sesión</h5>
    <Form role="form"
          class="text-start"
          :validation-schema="schema"
          @submit="handleInicioSesion"
          >

        <soft-field
            id="usuario"
            v-model="user.usuario"
            type="text"
            placeholder="Email"
            name="usuario"
            class="mb-4"
        />

        <soft-field
            id="password"
            v-model="user.password"
            type="password"
            placeholder="Clave"
            name="password"
            class="mb-4"
        />

        <div class="mt-5">
            <soft-button
                class="my-2 mb-2 "
                full-width
                color="tipster"
                :is-disabled="loading ? true : false"
                >
                <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                ></span>
                <span v-else class="text-sm">Acceder</span>
            </soft-button>
        </div>
    </Form>
  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "InicioSesionForm",
    components: {
        SoftField,
        SoftButton,
        Form,
    },
    data() {
        const schema = yup.object().shape({
            usuario: yup.string().required("Debe indicar su usuario!"),
            password: yup.string().required("Debe indicar su clave!"),
        });
        const user = {
            usuario: "",
            password: "",
        };
        return {
            loading: false,
            user,
            schema
        };
    },
    emits: ['cerrar-modal'],
    methods: {
      async handleInicioSesion() {
        // Aquí puedes realizar acciones cuando se envía el formulario
        console.log('Formulario enviado:', this.user);
        this.loading = true;
        try {
            await this.$store.dispatch("auth/login", this.user);

            // Obtengo el nombre del perfil para mostrar en cabecera
            await this.$store.dispatch("profile/getProfile");
            const resp = this.$store.getters["profile/profile"]; console.log(resp);
            localStorage.setItem("usuarioNombre",resp.name);
            localStorage.setItem("usuarioEmail",resp.email);
            localStorage.setItem("usuarioRolId",resp.rol_id);
            localStorage.setItem("usuarioTipsterId",resp.tipster_id);

            this.$router.push("/dashboard");
        } catch (error) {
            showSwal.methods.showSwal({
            type: "error",
            message: "Datos de acceso erróneos",
            });
            this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  .boton {

  }
  </style>