<template>
  <footer class="footer btn-dark">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-12 mb-lg-0 px-0">
          <div
            class="text-sm text-center copyright text-white"
            :class="this.$store.state.isRTL ? 'text-sm-end' : 'text-sm-start'"
          >
            ©{{ new Date().getFullYear() }} Tipster Verde
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>

<style>
.text-sm.copyright{font-size:0.75rem!important;line-height:1.2rem;margin-bottom:0;border:0!important;}
</style>